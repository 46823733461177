<template>
  <div>
    <Row :gutter="20" style="min-height:300px;">
      <i-col :lg="16" id="container1">

      </i-col>
      <i-col :lg="8" id="container2">

      </i-col>
    </Row>
  </div>
</template>

<script>
import { Chart, registerShape } from '@antv/g2';

export default {
  name: 'visit-chart',
  setup() {

  },
  created() {

  },
  mounted() {
    setTimeout(() => {
      this.init();
      this.schedule();
    }, 10);
  },
  methods: {
    init() {
      const data = [
        { type: '未知', value: 654, percent: 0.02 },
        { type: '17 岁以下', value: 654, percent: 0.02 },
        { type: '18-24 岁', value: 4400, percent: 0.2 },
        { type: '25-29 岁', value: 5300, percent: 0.24 },
        { type: '30-39 岁', value: 6200, percent: 0.28 },
        { type: '40-49 岁', value: 3300, percent: 0.14 },
        { type: '50 岁以上', value: 1500, percent: 0.06 },
      ];
      const chart = new Chart({
        container: 'container1',
        autoFit: true,
        height: 300,
        padding: [50, 20, 50, 20],
      });
      chart.data(data);
      chart.scale('value', {
        alias: '销售额(万)',
      });

      chart.axis('type', {
        tickLine: {
          alignTick: false,
        },
      });
      chart.axis('value', false);

      chart.tooltip({
        showMarkers: false,
      });
      chart.interval().position('type*value');
      chart.interaction('element-active');

      // 添加文本标注
      data.forEach((item) => {
        chart
          .annotation()
          .text({
            position: [item.type, item.value],
            content: item.value,
            style: {
              textAlign: 'center',
            },
            offsetY: -30,
          })
          .text({
            position: [item.type, item.value],
            content: (item.percent * 100).toFixed(0) + '%',
            style: {
              textAlign: 'center',
            },
            offsetY: -12,
          });
      });
      chart.render();

    },
    schedule() {
      registerShape('point', 'pointer', {
        draw(cfg, group) {
          const point = cfg.points[0];
          const center = this.parsePoint({ x: 0, y: 0 });
          const target = this.parsePoint({ x: point.x, y: 0.9 });
          const dir_vec = { x: center.x - target.x, y: center.y - target.y };
          // normalize
          const length = Math.sqrt(dir_vec.x * dir_vec.x + dir_vec.y * dir_vec.y);
          dir_vec.x *= (1 / length);
          dir_vec.y *= (1 / length);
          // rotate dir_vector by -90 and scale
          const angle1 = -Math.PI / 2;
          const x_1 = Math.cos(angle1) * dir_vec.x - Math.sin(angle1) * dir_vec.y;
          const y_1 = Math.sin(angle1) * dir_vec.x + Math.cos(angle1) * dir_vec.y;
          // rotate dir_vector by 90 and scale
          const angle2 = Math.PI / 2;
          const x_2 = Math.cos(angle2) * dir_vec.x - Math.sin(angle2) * dir_vec.y;
          const y_2 = Math.sin(angle2) * dir_vec.x + Math.cos(angle2) * dir_vec.y;
          // polygon vertex
          const path = [
            ['M', target.x + x_1 * 1, target.y + y_1 * 1],
            ['L', center.x + x_1 * 3, center.y + y_1 * 3],
            ['L', center.x + x_2 * 3, center.y + y_2 * 3],
            ['L', target.x + x_2 * 1, target.y + y_2 * 1],
            ['Z']
          ];
          const tick = group.addShape('path', {
            attrs: {
              path,
              fill: cfg.color
            }
          });
          return tick;
        }
      });

      const data = [
        { type: '新注册', value: 0.42 },
        { type: '老用户', value: 0.68 },
        { type: '留存量', value: 0.21 }
      ];
      const chart = new Chart({
        container: 'container2',
        autoFit: true,
        height: 500,
      });
      chart.data(data);
      chart.coordinate('polar', {
        startAngle: -10 / 8 * Math.PI,
        endAngle: 2 / 8 * Math.PI,
        radius: 0.75
      });
      chart.scale('value', {
        min: 0,
        max: 1,
        tickInterval: 1,
      });
      chart.axis(false);
      chart.facet('rect', {
        fields: ['type'],
        showTitle: false,
        eachView: function eachView(view, facet) {
          const data = facet.data[0];
          // 指针
          view
            .point()
            .position('value*1')
            .shape('pointer')
            .color('#d8d8d8')
            .animate({
              appear: {
                animation: 'fade-in',
              },
            });
          // 仪表盘背景
          view.annotation().arc({
            top: false,
            start: [0, 1],
            end: [1, 1],
            style: {
              stroke: '#ebedf0',
              lineWidth: 10
            }
          });
          // 仪表盘前景
          view.annotation().arc({
            start: [0, 1],
            end: [data.value, 1],
            style: {
              stroke: '#1890ff',
              lineWidth: 10
            }
          });
          // 仪表盘信息
          const percent = parseInt(data.value * 100, 10);

          view.annotation().text({
            position: ['50%', '70%'],
            content: data.type,
            style: {
              fontSize: 14,
              fill: '#8c8c8c',
              fontWeight: 300,
              textAlign: 'center'
            },
            offsetX: 0
          });
          view.annotation().text({
            position: ['50%', '75%'],
            content: `${percent}%`,
            style: {
              fontSize: 34,
              fill: '#000',
              fontWeight: 500,
              textAlign: 'center'
            },
            offsetX: 0,
            offsetY: 10
          });
        }
      });
      chart.render();
    }
  }
}
</script>
