import request from '@/utils/request'

const dashboard = {
    list: '/admin/panel'

}

export function getList(parameter) {
    return request({
        url: dashboard.list,
        method: 'get',
        params: parameter
    })
}