import axios from 'axios'
import store from '@/store'
import { Notice } from 'view-ui-plus'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = store.state.token
    if (error.response.status === 400) {
      Notice.error({
        title: '系统提示',
        desc: data.message
      })
    }
    if (error.response.status === 404) {
      Notice.error({
        title: '系统提示',
        desc: data.message
      })
    }
    if (error.response.status === 403) {
      Notice.error({
        title: '系统提示',
        desc: data.message
      })
    }
    if (error.response.status === 422) {
      Notice.error({
        title: '系统提示',
        desc: data.message
      })
    }
    if (error.response.status === 401) {
      Notice.error({
        title: '系统提示',
        desc: '登录过期，请重新登录'
      })
      store.dispatch('logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
    }
    if (error.response.status === 500) {
      Notice.error({
        title: '系统提示',
        desc: data.message
      })
    }
    if (error.response.status === 0) {
      Notice.error({
        title: '系统提示',
        desc: '网络错误'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = store.state.token
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request