<template>
  <MainLayout />
</template>

<script>
import MainLayout from '@/views/layout/MainLayout'
export default {
  name: 'HomeView',
  components: {
    MainLayout
  }
}
</script>
