<template>
  <div class="i-layout-sider-logo">
    <a href="/" target="_self" class="i-link i-link-color">
      <img v-if="!isCollapsed" src="@/assets/logo-dark.1a60eea3.png" />
      <img v-else src="@/assets/logo-small.4a34a883.png" />
    </a>
  </div>
  <Menus :isCollapsed="isCollapsed" />
</template>

<script>
import Menus from '@/views/permission/Menus.vue'
export default {
  name: 'SiderLayout',
  props: {
    isCollapsed: Boolean
  },
  components: {
    Menus
  }
}
</script>
