<template>
  <div class="container">
    <div class="login-panel">
      <PageHeader hidden-breadcrumb style="background: transparent;">
        <template #content>
          <div style="text-align: center;"><img src="../../assets/logo.fe4f63bf.png" height="75" /></div>
          <div style="text-align: center;">智慧社区管理控制台</div>
        </template>
      </PageHeader>
      <div class="demo-login">
        <Login @on-submit="handleSubmit">
          <UserName name="username" />
          <Password name="password" />
          <div class="demo-auto-login">
            <Checkbox v-model="autoLogin" size="large">自动登录</Checkbox>
            <a>忘记密码</a>
          </div>
          <Submit />
        </Login>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getUser } from '@/api/login'
import { timeFix } from '@/utils/util'
import { addRoutes } from '@/router'
export default {
  name: 'Menus',
  props: {

  },
  setup() {

  },
  data() {
    return {
      autoLogin: true
    }
  },
  created() {

  },
  watch: {

  },
  methods: {
    handleSubmit(valid, { username, password }) {
      if (!valid) {
        this.$Notice.warning({ title: '登录提醒', desc: '请输入用户名与密码' })
      } else {
        login({ 'username': username, 'password': password }).then(async res => {
          if (res.code === 0) {
            this.$store.commit('setToken', res.data.token)
            await this.getUserInfo();
            this.$router.push({ path: '/dahsboard/console' })
          } else {
            this.$Notice.error({ title: '登录失败', desc: res.message })
          }
        })
      }
    },
    async getUserInfo() {
      return await getUser().then(res => {
        if (res.code == 0) {
          this.$store.commit('setUserInfo', res.data.user);
          this.$store.commit('setMenus', res.data.menus);
          addRoutes(res.data.menus);
          this.$Notice.success({ title: res.data.user.username, desc: `${timeFix()}，欢迎回来` })
        }
      })
    }

  }
}
</script>
<style lang="less" scoped>
.container {
  background: #0e0f3a;
  position: relative;
}

.container::before {
  content: " ";
  background-image: url(@/assets/0183c657b3bf650000012e7e841005.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .18;
}

.login-panel {
  background: #fff;
  padding: 15px 30px 30px;
  transform: translateY(50%);
  width: 460px;
  margin: 0 auto !important;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

.demo-login {
  width: 400px;
  margin: 0 auto !important;
}

.demo-auto-login {
  margin-bottom: 24px;
  text-align: left;
}

.demo-auto-login a {
  float: right;
}

.page-account-other {
  margin: 24px 0;
  text-align: left;
}

.page-account-other img {
  width: 24px;
  margin-left: 16px;
  cursor: pointer;
  vertical-align: middle;
  opacity: .7;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.page-account-register {
  float: right;
}
</style>