import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { LoadingBar } from 'view-ui-plus'
import Home from '@/views/Home.vue'
import Login from '@/views/login/Login.vue'
import Console from '@/views/dashboard/console.vue'

const whiteList = ['login']
const defaultRoutePath = '/dahsboard/console'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/dahsboard/console',
    children: [
      {
        path: '/dahsboard',
        name: 'dashboard',
        redirect: '/dahsboard/console',
        title: '控制台',
        icon: "md-speedometer",
        type: 'menu',
        children: [
          {
            path: '/dahsboard/console',
            name: 'dashboardConsole',
            title: '控制台',
            icon: "",
            type: 'menu',
            component: Console,
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

// 动态添加路由
const addRoutes = (menus) => {
  menus.forEach(menu => {
    // 根据菜单数据生成路由配置
    const route = {
      path: menu.path,
      name: menu.name,
      title: menu.title,
      type: menu.type,
      icon: menu.icon,
      component: () => import(`@/views${menu.component}`),// 按需加载视图组件
      children: []
    };
    // 如果菜单有子菜单，递归调用添加子路由
    if (menu.children && menu.children.length > 0) {
      menu.children.forEach((item, index) => {
        route.children.push({
          path: item.path,
          name: item.name,
          title: item.title,
          type: item.type,
          icon: item.icon,
          component: () => import(`@/views${item.component}`),// 按需加载视图组件
        })
      });
    }
    // 添加路由
    router.addRoute('Home', route)
  });
}

router.beforeEach(async (to, from, next) => {
  LoadingBar.start()
  if (store.state.token) {
    LoadingBar.finish()
    if (to.path == '/login') {
      next({ path: defaultRoutePath })
    } else {
      //如果路由不存在则动态添加
      if (router.hasRoute(to.name)) {
        next()
      } else {
        await addRoutes(store.state.routes);
        next({ path: to.fullPath, replace: true })
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: '/login' })
      LoadingBar.finish()
    }
  }
})

router.afterEach(() => {
  LoadingBar.finish()
})

export { router, addRoutes };
