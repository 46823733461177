<template>
  <div style="text-align:center">
    2022-2028 &copy; 万户网络
  </div>
</template>

<script>

export default {
  name: 'FooterLayout',
  components: {

  }
}
</script>
