<template>
  <Menu
    v-if="!isCollapsed"
    theme="dark"
    :open-names="openNames"
    :active-name="activeName"
    accordion
    style="width: 100%"
    @on-select="handleClick"
    ref="menu"
  >
    <template v-for="(item, index) in routes" :key="index">
      <Submenu :name="item.name" v-if="item.type == 'menu'">
        <template #title>
          <Icon :type="item.icon" size="18" />{{ item.title }}
        </template>
        <template v-for="(child, i) in item.children" :key="i">
          <MenuItem
            :name="child.name"
            v-if="child.type == 'menu' && child.visible"
          >
            <span style="padding-left: 20px">{{ child.title }}</span>
          </MenuItem>
        </template>
      </Submenu>
    </template>
  </Menu>
  <!--下面是左侧关闭时菜单-->
  <Menu v-else theme="dark" style="width: 100%">
    <template v-for="(item, index) in routes" :key="index">
      <MenuItem :name="item.name" v-if="item.type == 'menu'">
        <Dropdown
          placement="right"
          transfer
          transfer-class-name="transfer-class-name"
          @on-click="checkMenu"
        >
          <Icon :type="item.icon" size="18" />
          <template #list>
            <DropdownMenu>
              <template v-for="(child, i) in item.children" :key="i">
                <DropdownItem :name="child.name" v-if="child.type == 'menu'">{{
                  child.title
                }}</DropdownItem>
              </template>
            </DropdownMenu>
          </template>
        </Dropdown>
      </MenuItem>
    </template>
  </Menu>
</template>

<script>
export default {
  name: "Menus",
  props: {
    isCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  setup() {},
  data() {
    return {
      activeName: "",
      openNames: ["dashboard"],
      tabList: this.$store.state.tabList,
      routes: this.$store.state.routes,
    };
  },
  created() {
    var path = this.$route.path;
    if (path != "/") {
      this.toPath(path);
    } else {
      this.defaultMethod();
    }
  },
  watch: {
    "$store.state.tabList"(val) {
      if (val.length > 0) {
        this.tabList = val;
      }
    },
    "$store.state.tabActive"(val) {
      this.activeName = val;
      this.openMenu(val);
    },
    "$store.state.routes": {
      handler(val) {
        this.routes = val;
      },
      deep: true,
    },
  },
  methods: {
    defaultMethod() {
      //默认打开第一个菜单
      this.tabList = [];
      var menu = this.routes;
      if (!this.tabList.includes(menu)) {
        this.tabList.push(menu);
      }
      this.$router.push({ path: menu.path });
      this.$store.commit("setMenuId", menu.name);
      this.$store.commit("setTabList", this.tabList);
      this.$store.commit("setTabActive", menu.name);
      this.$store.commit("setTabIndex", 0);
    },
    toPath(path) {
      this.openNames = [];
      this.routes.forEach((menu) => {
        menu.children.forEach((child) => {
          if (child.path === path) {
            var index = this.tabList.findIndex(
              (ele) => ele.name === child.name
            );
            if (index == -1) {
              this.tabList.push(child);
              this.$store.commit("setTabList", this.tabList);
            }
            this.$store.commit("setTabActive", child.name);
            this.$store.commit(
              "setTabIndex",
              this.tabList.findIndex((ele) => ele.name === child.name)
            );
            this.activeName = child.name;
            this.openNames.push(menu.name);
            if (this.$store.state.menuId != child.name) {
              this.$store.commit("setMenuId", child.name);
            }
          }
        });
      });
      this.$store.commit("setMenuList", this.routes);
    },
    handleClick(name) {
      this.routes.forEach((menu) => {
        menu.children.forEach((child) => {
          if (child.name === name) {
            var index = this.tabList.findIndex(
              (ele) => ele.name === child.name
            );
            if (index == -1) {
              this.tabList.push(child);
              this.$store.commit("setTabList", this.tabList);
            }
            this.activeName = child.name;
            this.$store.commit("setTabActive", child.name);
            this.$store.commit(
              "setTabIndex",
              this.tabList.findIndex((ele) => ele.name === child.name)
            );
            this.$store.commit("setMenuId", child.name);
            this.$router.push({ path: child.path });
          }
        });
      });
    },
    openMenu(name) {
      this.openNames = [];
      this.routes.forEach((menu) => {
        menu.children.forEach((item) => {
          if (item.name === name) {
            this.openNames.push(menu.name);
          }
        });
      });
      //解决iview的BUG
      this.$nextTick(() => {
        if (!this.isCollapsed) {
          this.$refs.menu.updateOpened();
          this.$refs.menu.updateActiveName();
        }
      });
    },
    checkMenu(name) {
      this.routes.forEach((menu) => {
        menu.children.forEach((child) => {
          if (child.name === name) {
            var index = this.tabList.findIndex(
              (ele) => ele.name === child.name
            );
            if (index == -1) {
              this.tabList.push(child);
              this.$store.commit("setTabList", this.tabList);
            }
            this.activeName = child.name;
            this.$store.commit("setTabActive", child.name);
            this.$store.commit(
              "setTabIndex",
              this.tabList.findIndex((ele) => ele.name === child.name)
            );
            this.$store.commit("setMenuId", child.name);
            this.$router.push({ path: child.path });
          }
        });
      });
    },
  },
};
</script>
<style>
.transfer-class-name {
  left: 80px !important;
}

.i-layout-menu-side .ivu-menu-item,
.i-layout-menu-side .ivu-menu-submenu-title {
  height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>