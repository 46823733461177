<template>
    <span class="i-layout-header-trigger i-layout-header-trigger-min" @click="collapsedSider">
        <i class="ivu-icon i-icon i-icon-menu-unfold" :style="{ display: !isCollapsed ? 'none' : '' }"></i>
        <i class="ivu-icon i-icon i-icon-menu-fold" :style="{ display: isCollapsed ? 'none' : '' }"></i>
    </span>
    <span class="i-layout-header-trigger i-layout-header-trigger-min"><i class="ivu-icon i-icon i-icon-refresh"></i></span>
    <Breadcrumb />
    <div class="i-layout-header-right">
        <Tooltip class="i-layout-header-trigger i-layout-header-trigger-min" content="没有日志或异常" placement="bottom" transfer>
            <Icon type="md-radio-button-on" size="18" />
        </Tooltip>
        <Tooltip class="i-layout-header-trigger i-layout-header-trigger-min" content="全屏非全屏" placement="bottom" transfer>
            <Icon type="md-expand" size="18" />
        </Tooltip>
        <Notification class="i-layout-header-trigger i-layout-header-trigger-min" auto-count @on-load-more="handleLoadMore"
            @on-clear="handleClear" transfer>
            <template #icon>
                <Icon type="md-notifications-outline" size="18" />
            </template>
            <NotificationTab title="通知" name="message" :count="unreadMessage" :loaded-all="messageList.length >= 15"
                :loading="messageLoading" :scroll-to-load="false">
                <NotificationItem v-for="(item, index) in messageList" :key="index" :title="item.title" :icon="item.icon"
                    :icon-color="item.iconColor" :time="item.time" :read="item.read" />
            </NotificationTab>
            <NotificationTab title="关注" name="follow" :count="unreadFollow" :loaded-all="followList.length >= 15"
                :loading="followLoading" :scroll-to-load="false">
                <NotificationItem v-for="(item, index) in followList" :key="index" :avatar="item.avatar" :title="item.title"
                    :time="item.time" :read="item.read" />
            </NotificationTab>
            <NotificationTab title="待办" name="todo" :count="unreadTodo" :loaded-all="todoList.length >= 15"
                :loading="todoLoading" :scroll-to-load="false">
                <NotificationItem v-for="(item, index) in todoList" :key="index" :title="item.title" :content="item.content"
                    :tag="item.tag" :tag-props="item.tagProps" :read="item.read" />
            </NotificationTab>
        </Notification>
        <Dropdown class="i-layout-header-trigger i-layout-header-trigger-min" transfer @on-click="userBtn">
            <span>
                <span class="ivu-avatar ivu-avatar-circle ivu-avatar-image ivu-avatar-small">
                    <img src="@/assets/avatar.jpg" />
                </span>
                <span class="i-layout-header-user-name">{{ userInfo.username || '管理员' }}</span>
            </span>
            <template #list>
                <DropdownMenu>
                    <DropdownItem name='user'>
                        <Icon type="ios-person-outline" size="16" /> 个人中心
                    </DropdownItem>
                    <DropdownItem name='password'>
                        <Icon type="ios-lock-outline" size="16" /> 修改密码
                    </DropdownItem>
                    <DropdownItem name='logout'>
                        <Icon type="ios-exit-outline" size="16" /> 退出登录
                    </DropdownItem>
                </DropdownMenu>
            </template>
        </Dropdown>
        <!-- <Dropdown class="i-layout-header-trigger i-layout-header-trigger-min" transfer>
            <i class="ivu-icon i-icon i-icon-i18n"></i>
            <template #list>
                <DropdownMenu>
                    <DropdownItem>简体中文</DropdownItem>
                    <DropdownItem>English</DropdownItem>
                </DropdownMenu>
            </template>
        </Dropdown> -->
        <Tooltip class="i-layout-header-trigger i-layout-header-trigger-min" content="主题设置" placement="bottom" transfer>
            <Icon type="md-more" size="18" />
        </Tooltip>
    </div>
</template>

<script>
import Breadcrumb from '@/views/permission/Breadcrumb.vue'
export default {
    name: 'HeaderLayout',
    emits: ['collapsedSider'],
    props: {
        isCollapsed: Boolean
    },
    components: {
        Breadcrumb
    },
    data() {
        return {
            messageBaseList: [
                // {
                //     icon: 'md-mail',
                //     iconColor: '#3391e5',
                //     title: '蒂姆·库克回复了你的邮件',
                //     read: 1,
                //     time: 1557297198
                // }
            ],
            followBaseList: [
                // {
                //     avatar: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
                //     title: '史蒂夫·乔布斯 关注了你',
                //     read: 1,
                //     time: 1557299299
                // }
            ],
            todoBaseList: [
                // {
                //     title: '2019 下半年 OKR',
                //     content: '需要在 2019-06-14 之前完成',
                //     tag: '未开始',
                //     tagProps: {
                //         color: 'default'
                //     },
                //     read: 1
                // }
            ],
            messageList: [],
            followList: [],
            todoList: [],
            messageLoading: false,
            followLoading: false,
            todoLoading: false,
            userInfo: {}

        }
    },
    created() {
        if (JSON.stringify(this.$store.getters.userinfo) != '{}') {
            this.userInfo = this.$store.getters.userinfo;
        }
    },
    computed: {
        unreadMessage() {
            let unread = 0;
            this.messageList.forEach(item => {
                if (!item.read) unread++;
            });
            return unread;
        },
        unreadFollow() {
            let unread = 0;
            this.followList.forEach(item => {
                if (!item.read) unread++;
            });
            return unread;
        },
        unreadTodo() {
            let unread = 0;
            this.todoList.forEach(item => {
                if (!item.read) unread++;
            });
            return unread;
        }
    },
    methods: {
        collapsedSider() {
            this.$emit('collapsedSider')
        },
        userBtn(name) {
            if (name === 'logout') {
                this.$store.commit('logout', '')
                this.$router.push({ path: '/login' })
            } else if (name === 'user') {
                this.$router.push({ path: '/setting/account' })
            } else {
                this.$router.push({ path: '/setting/password' })
            }
        },
        handleLoadMore(tab) {
            this.loadMore(tab.name);
        },
        loadMore(type) {
            if (this[type + 'Loading']) return;
            this[type + 'Loading'] = true;
            setTimeout(() => {
                this[type + 'List'] = this[type + 'List'].concat([...this[type + 'BaseList']]);
                this[type + 'Loading'] = false;
            }, 1000);
        },
        handleClear(tab) {
            this.clearUnread(tab.name);
        },
        clearUnread(type) {
            this[type + 'List'] = this[type + 'List'].map(item => {
                item.read = 1;
                return item;
            });
        }
    },
    mounted() {
        this.messageList = [...this.messageBaseList];
        this.followList = [...this.followBaseList];
        this.todoList = [...this.todoBaseList];
    }
}
</script>
<style scoped></style>
