<template>
  <div class="ivu-breadcrumb i-layout-header-breadcrumb">
    <Breadcrumb v-if="menuList.length > 0">
      <BreadcrumbItem v-for="menu in menuList" :key="menu.id" :to="menu.menuLink" style="font-weight:400">
        <template v-if="!menu.child">
          <div class="i-layout-menu-head-title">
            <span class="i-layout-menu-head-title-text">{{ menu.menuName }}</span>
          </div>
        </template>
        <template v-else>
          <div class="i-layout-menu-head-title">
            <span class="i-layout-menu-head-title-text">{{ menu.name }}</span>
          </div>
        </template>
      </BreadcrumbItem>
    </Breadcrumb>
  </div>
</template>

<script>
import * as menuUtils from '@/utils/MenuUtils.js'
export default {
  name: 'Menus',
  props: {

  },
  setup() {

  },
  data() {
    return {
      menuList: [],
      menuApply: '',
      menus: this.$store.state.menuList,
      menuId: this.$store.state.menuId
    }
  },
  created() {
    if (this.menuId && this.menus) {
      this.menuList = menuUtils.getMenuBreadcrumb(this.menus, this.menuApply, this.menuId);
    }
  },
  watch: {
    '$store.state.menuId'(val) {
      if (val) {
        this.menuId = this.$store.state.menuId;
        if (this.menus) {
          this.menuList = menuUtils.getMenuBreadcrumb(this.menus, this.menuApply, this.menuId);
        }
      }
    }
  },
  methods: {

  }
}
</script>
<style  scoped>
.home-tabs :deep(.ivu-tabs-bar) {
  border-bottom: none;
  margin-bottom: 0px;
}

.home-tabs :deep(.ivu-tabs.ivu-tabs-card)>.ivu-tabs-bar .ivu-tabs-tab {
  border: none;
  background-color: #fff;
}
</style>