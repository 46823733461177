<template>
  <div style="padding: 15px 20px">
    <Row :gutter="20" style="margin-bottom: 10px">
      <i-col :lg="12" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 数据总览 </template>
          <template #extra> </template>
          <Row style="padding-left: 80px; margin-bottom: 10px; cursor: pointer">
            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="楼宇" @click="gotoUrl('/building/list')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.building" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="企业入驻"
                @click="gotoUrl('/company/list')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.company" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="重大项目"
                @click="gotoUrl('/project/list')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.project" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>

          <Row style="padding-left: 80px; cursor: pointer">
            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="招商信息"
                @click="gotoUrl('/business/list')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.business" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="出租房屋" @click="gotoUrl('/house/index')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.house" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="招聘信息" @click="gotoUrl('/job/list')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.all.job" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="12" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 待审核信息 </template>
          <template #extra> </template>
          <Row style="padding-left: 80px; margin-bottom: 10px; cursor: pointer">
            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审核志愿者"
                @click="gotoUrl('/activity/volunteer')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.volunteer"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审党员认证"
                @click="gotoUrl('/activity/partyMember')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.party_member"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审入驻企业"
                @click="gotoUrl('/company/list?status')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.company"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>
          </Row>

          <Row style="padding-left: 80px; cursor: pointer">
            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审招商"
                @click="gotoUrl('/business/list')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.business"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审出租房源"
                @click="gotoUrl('/house/index')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.house"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>

            <i-col :lg="8" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待审招聘"
                @click="gotoUrl('/job/list?status=0')"
              >
                <template #title>
                  <span style="font-size: 30px">{{
                    state.completed_volume
                  }}</span>
                </template>
                <template #total>
                  <Numeral
                    :value="info.review.job"
                    format="0,0"
                    style="color: red"
                  />
                </template>
              </NumberInfo>
            </i-col>
          </Row>
        </Card>
      </i-col>
    </Row>

    <Row :gutter="20" style="margin-bottom: 10px; cursor: pointer">
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 企业问题反馈 </template>
          <template #extra> </template>
          <Row style="padding-left: 20px">
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待分派"
                @click="gotoUrl('/company/report')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.feedback.report" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="待处理"
                @click="gotoUrl('/company/dispatch')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.feedback.dispatch" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待办结" @click="gotoUrl('/company/deal')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.feedback.deal" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="已办结"
                @click="gotoUrl('/company/complete')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.feedback.complete" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>

          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总数量 </i-col>
            <i-col :lg="12" style="text-align: right"> {{}} </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 一键求助 </template>
          <template #extra> </template>
          <Row style="padding-left: 20px">
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待分派" @click="gotoUrl('/help/report')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.help.report" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待处理" @click="gotoUrl('/help/dispatch')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.help.dispatch" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待办结" @click="gotoUrl('/help/deal')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.help.deal" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="已办结" @click="gotoUrl('/help/complete')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.help.complete" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总数量 </i-col>
            <i-col :lg="12" style="text-align: right"> {{}} </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 随手拍 </template>
          <template #extra> </template>
          <Row style="padding-left: 20px">
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待分派" @click="gotoUrl('/shot/report')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.shot.report" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待处理" @click="gotoUrl('/shot/dispatch')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.shot.dispatch" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待办结" @click="gotoUrl('/shot/deal')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.shot.deal" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="已办结" @click="gotoUrl('/shot/complete')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.shot.complete" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总数量 </i-col>
            <i-col :lg="12" style="text-align: right"> {{}} </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 微心愿 </template>
          <template #extra> </template>
          <Row style="padding-left: 20px">
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="待审核" @click="gotoUrl('/wishe/default')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.wish.apply" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo
                sub-title="未认领"
                @click="gotoUrl('/wishe/un_receive')"
              >
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.wish.un_receive" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="已认领" @click="gotoUrl('/wishe/receive')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.wish.receive" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
            <i-col :lg="6" :xs="24" :sm="12" :md="12">
              <NumberInfo sub-title="已完成" @click="gotoUrl('/wishe/finish')">
                <template #title>
                  <span style="font-size: 30px"></span>
                </template>
                <template #total>
                  <Numeral :value="info.wish.finish" format="0,0" />
                </template>
              </NumberInfo>
            </i-col>
          </Row>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总数量 </i-col>
            <i-col :lg="12" style="text-align: right"> {{}} </i-col>
          </Row>
        </Card>
      </i-col>
    </Row>

    <Row :gutter="20">
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 访问量 </template>
          <template #extra>
            <Tag color="success">日</Tag>
          </template>
          <NumberInfo sub-title="近7日访问量" sub-total="17.1" status="up">
            <template #title>
              <span style="font-size: 30px">{{ state.completed_volume }}</span>
            </template>
            <template #total>
              <Numeral value="121" format="0,0" />
            </template>
          </NumberInfo>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总访问量 </i-col>
            <i-col :lg="12" style="text-align: right"> 300万 </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 租房数据 </template>
          <template #extra>
            <Tag color="primary">周</Tag>
          </template>
          <NumberInfo sub-title="近7日统计" sub-total="17.1" status="up">
            <template #title>
              <span style="font-size: 30px">{{ state.contract_volume }}</span>
            </template>
            <template #total>
              <Numeral value="2" format="0,0" />
            </template>
          </NumberInfo>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总出租数量 </i-col>
            <i-col :lg="12" style="text-align: right"> 300 </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 楼宇信息 </template>
          <template #extra>
            <Tag color="red">月</Tag>
          </template>
          <h2 style="font-size: 30px">{{ state.next_contract_volume }}</h2>
          <p style="height: 78px">
            <v-chart class="chart" :option="dingdan" :autoresize="true" />
          </p>

          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总数据量 </i-col>
            <i-col :lg="12" style="text-align: right"> 100 </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :lg="6" :xs="24" :sm="12" :md="12">
        <Card style="width: 100%">
          <template #title> 新增用户数 </template>
          <template #extra>
            <Tag color="success">年</Tag>
          </template>
          <NumberInfo sub-title="近7日访问量" sub-total="17.1" status="up">
            <template #title>
              <span style="font-size: 30px">{{
                state.next_declared_volume
              }}</span>
            </template>
            <template #total>
              <Numeral value="320" format="0,0" />
            </template>
          </NumberInfo>
          <Divider style="margin: 8px 0px"></Divider>
          <Row>
            <i-col :lg="12"> 总用户数 </i-col>
            <i-col :lg="12" style="text-align: right"> 300万 </i-col>
          </Row>
        </Card>
      </i-col>
    </Row>
    <!-- <Row :gutter="20" style="margin-top:10px;">
      <i-col :lg="3" v-for="item in operationList" :key="item.title">
        <Card style="text-align:center">
          <Icon :type="item.icon" size="32" :color="item.color"></Icon>
          <div style="margin-top: 8px;">{{ item.title }}</div>
        </Card>
      </i-col>
    </Row> -->
    <Card class="card" style="margin-top: 10px">
      <template #title>
        <Avatar
          icon="ios-pulse"
          size="24"
          :style="{
            color: 'rgb(24, 144, 255)',
            'background-color': 'rgb(230, 247, 255)',
          }"
        ></Avatar>
        活跃用户年龄分布
      </template>
      <template #extra>
        <RadioGroup v-model="button2" type="button">
          <Radio label="今日"></Radio>
          <Radio label="本月"></Radio>
          <Radio label="全年"></Radio>
        </RadioGroup>
        <DatePicker
          :model-value="['2022-07-08', '2022-07-25']"
          type="daterange"
          split-panels
          placeholder="Select date"
          style="width: 200px; margin-left: 5px"
        ></DatePicker>
      </template>
      <AppChart></AppChart>
    </Card>
  </div>
</template>

<script>
import AppChart from "@/components/chart-g2/app-chart.vue";
import { getList } from "@/api/dashboard";

import * as echarts from "echarts";
export default {
  name: "Demo",
  components: {
    AppChart,
  },
  data() {
    return {
      operationList: [
        {
          title: "用户",
          icon: "md-people",
          color: "rgb(105, 192, 255)",
        },
        {
          title: "分析",
          icon: "md-trending-up",
          color: "rgb(149, 222, 100)",
        },
        {
          title: "商品",
          icon: "md-cart",
          color: "rgb(255, 156, 110)",
        },
        {
          title: "订单",
          icon: "md-clipboard",
          color: "rgb(179, 127, 235)",
        },
        {
          title: "票据",
          icon: "md-card",
          color: "rgb(255, 214, 102)",
        },
        {
          title: "消息",
          icon: "md-mail",
          color: "rgb(92, 219, 211)",
        },
        {
          title: "标签",
          icon: "md-pricetags",
          color: "rgb(255, 133, 192)",
        },
        {
          title: "配置",
          icon: "md-switch",
          color: "rgb(255, 192, 105)",
        },
      ],
      button2: "",
      state: {
        completed_volume: "",
        contract_volume: "",
        next_contract_volume: "",
        ext_declared_volume: "",
      },
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(9, 24, 48, 0.5)",
          borderColor: "rgba(75, 253, 238, 0.4)",
          textStyle: {
            color: "#CFE3FC",
          },
          borderWidth: 1,
        },
        grid: {
          top: "10%",
          right: "5%",
          left: "5%",
          bottom: "8%",
        },
        xAxis: [
          {
            name: "",
            type: "category",
            data: [
              "一月",
              "二月",
              "三月",
              "四月",
              "五月",
              "六月",
              "七月",
              "八月",
              "九月",
              "十月",
              "十一月",
              "十二月",
            ],
            axisLine: {
              lineStyle: {
                color: "#f2f2f2",
              },
            },
            axisLabel: {
              margin: 10,
              color: "#666",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255,255,255,.8)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "访问量趋势",
            type: "value",
            axisLabel: {
              formatter: "{value}",
              color: "#333",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#f2f2f2",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255,255,255,.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [
              2000, 1520, 1850, 3400, 2756, 1520, 1850, 3400, 2756, 1520, 1850,
              3400,
            ],
            barWidth: "40%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,244,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,77,167,1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0,160,221,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 10,
                formatter: "{c}",
                position: "top",
                textStyle: {
                  color: "#00D6F9",
                  fontSize: 12,
                },
              },
            },
          },
        ],
      },
      dingdan: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "04-27",
              "04-28",
              "04-29",
              "04-30",
              "05-01",
              "05-02",
              "05-03",
            ],
            axisLabel: {
              show: false,
              color: "rgba(187, 209, 238, 1)",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(35, 72, 124, 1.00)",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "#315070",
              },
            },
            axisLabel: {
              show: false,
              color: "rgba(187, 209, 238, 1)",
            },
            axisLine: {
              lineStyle: {
                color: "rgba(35, 72, 124, 1.00)",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            stack: "总量",
            symbol: "circle",
            smooth: true,
            symbolSize: 12,
            areaStyle: {
              color: "rgba(39, 212, 125, 1.00)",
              opacity: 0.2,
            },
            lineStyle: {
              color: "rgba(0, 204, 102, 1.00)",
            },
            itemStyle: {
              color: "rgba(9, 173, 245, 1.00)",
              opacity: 0,
            },
            emphasis: {
              focus: "series",
              itemStyle: {
                opacity: 1,
                borderWidth: 3,
                borderColor: "#fff",
                shadowColor: "rgba(39, 212, 125, 0.5)",
                shadowBlur: 10,
              },
            },
            data: [120, 162, 31, 184, 90, 230, 110],
          },
        ],
      },
      info: {
        all: {},
        feedback: {},
        review: {},
        help: {},
        shot: {},
        wish: {},
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleClick() {
      this.$Message.info("Welcome to View UI Plus Demo!");
    },
    getData() {
      getList({}).then((res) => {
        this.info = res.data;
      });
    },
    gotoUrl(path) {
      this.$store.state.routes.forEach((menu) => {
        menu.children.forEach((child) => {
          if (child.path === path) {
            var index = this.$store.state.tabList.findIndex(
              (ele) => ele.name === child.name
            );
            if (index == -1) {
              this.$store.state.tabList.push(child);
              this.$store.commit("setTabList", this.$store.state.tabList);
            }
            this.$store.commit("setTabActive", child.name);
            this.$store.commit(
              "setTabIndex",
              this.$store.state.tabList.findIndex(
                (ele) => ele.name === child.name
              )
            );
            this.activeName = child.name;
            // this.openNames.push(menu.name)
            if (this.$store.state.menuId != child.name) {
              this.$store.commit("setMenuId", child.name);
            }
          }
        });
      });
      this.$store.commit("setMenuList", this.$store.state.routes);
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="less" scoped></style>
