<template>
    <div class="ivu-layout">
        <Layout :style="{ background: '#fff', minHeight: clientHeight + 'px' }">
            <Sider :style="{ position: 'fixed', height: '100vh', left: 0, overflow: 'auto' }"
                class="ivu-layout-has-sider i-layout-sider-dark" ref="side1" hide-trigger collapsible :collapsed-width="78"
                v-model="isCollapsed" :width="256">
                <SiderLayout :isCollapsed="isCollapsed" />
            </Sider>
            <Layout :style="{ marginLeft: isCollapsed ? '78px' : '256px' }">
                <div :style="{ position: 'fixed', top: 0, width: isCollapsed ? 'calc(100% - 78px)' : 'calc(100% - 256px)' }"
                    class="layout-header-bar" style="z-index: 900;background:#f5f7f9">
                    <Header :style="{ padding: 0, background: '#ffffff' }" class="layout-header-bar">
                        <HeaderLayout :isCollapsed="isCollapsed" v-on:collapsed-sider="collapsedSider" />
                    </Header>
                    <Tabs />
                </div>

                <Content style="margin-top:108px">
                    <router-view></router-view>
                </Content>
                <Footer>
                    <FooterLayout />
                </Footer>
            </Layout>
        </Layout>
    </div>
</template>
<script>
import HeaderLayout from './HeaderLayout.vue'
import FooterLayout from './FooterLayout.vue'
import Tabs from '@/views/permission/Tabs.vue'
import SiderLayout from './SiderLayout.vue'
export default {
    emits: ['collapsedSider'],
    name: 'MainLayout',
    components: {
        HeaderLayout, FooterLayout, Tabs, SiderLayout
    },
    data() {
        return {
            isCollapsed: false,
            clientHeight: 550,
        }
    },
    watch: {

    },
    mounted() {
        this.clientHeight = `${document.documentElement.clientHeight}`       //document.body.clientWidth;
        window.onresize = function temp() {
            this.clientHeight = `${document.documentElement.clientHeight}`;
        };
    },
    computed: {
        rotateIcon() {
            return [
                'menu-icon',
                this.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        menuitemClasses() {
            return [
                'menu-item',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
    },
    methods: {
        collapsedSider() {
            this.$refs.side1.toggleCollapse();
        }
    }
}
</script>
<style scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout .ivu-menu {
    z-index: 0
}

.layout-header-bar {

    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}

.layout-logo-left {
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
}

.ivu-layout-has-sider {
    z-index: 1000
}

.menu-icon {
    transition: all .3s;
}

.rotate-icon {
    transform: rotate(-90deg);
}

.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
}

.menu-item i {
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
}

.collapsed-menu span {
    width: 0px;
    transition: width .2s ease;
}

.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
}
</style>