import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    menuId: '', // 选中菜单
    tabList: [],
    token: '',
    routes: [],
    menuList: [],
    tabActive: '',
    tabIndex: 0,
    userinfo: null,
  },
  getters: {
    userinfo(state) {
      return state.userinfo || {};
    },
    token(state) {
      return state.token || {};
    },
    menus(state) {
      return state.routes || {};
    }
  },
  mutations: {
    setMenuId(state, menuId) {
      state.menuId = menuId
    },
    setTabList(state, tabList) {
      state.tabList = tabList
    },
    setToken(state, token) {
      state.token = token
    },
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    setTabActive(state, tabActive) {
      state.tabActive = tabActive
    },
    setTabIndex(state, tabIndex) {
      state.tabIndex = tabIndex
    },
    setUserInfo(state, data) {
      state.userinfo = data
    },
    setMenus(state, data) {
      state.routes = data
    },
    logout(state, data) {
      state.token = 0;
      state.userinfo = null;
    },
  },
  actions: {
    setToken(context, data) {
      context.commit("setToken", data);
    },
    setUserInfo(context, data) {
      context.commit("setUserInfo", data);
    },
    setMenus(context, data) {
      context.commit("setMenus", data);
    },
    logout(context, data) {
      context.commit("logout", data);
    }
  },
  modules: {

  },
  plugins: [createPersistedState()]
})
